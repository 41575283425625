import React from 'react';

function Purchase(props) {
  return (
    <>
      <a href="https://tuacahn.org" target="_blank" rel="noopener noreferrer">
        <ul className="buy-button" style={{ listStyle: 'none' }}>
          <li>Buy Tickets</li>
        </ul>
      </a>
    </>
  );
}

export default Purchase;
