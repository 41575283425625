import Randomizer from "../../../../src/docs/components/randomizer";
import ClientRandomizer from "../../../../src/docs/components/clientrandomizer";
import MillsCard from "../../../../src/docs/components/millscard";
import Break from "../../../../src/docs/components/break";
import Zoom from 'react-reveal/Zoom'; // Importing Zoom effect

import Agrusa from "../../../../src/docs/assets/profiles/Agrusa-Ashley.jpg";
import Baker from "../../../../src/docs/assets/profiles/baker.jpg";
import Caughell from "../../../../src/docs/assets/profiles/caughell.jpg";
import Coates from "../../../../src/docs/assets/profiles/coates.jpg";
import Colburn from "../../../../src/docs/assets/profiles/colburn.jpg";
import Failoni from "../../../../src/docs/assets/profiles/failoni.jpg";
import Germain from "../../../../src/docs/assets/profiles/germain.jpg";
import Gibbons from "../../../../src/docs/assets/profiles/gibbons.jpg";
import HarperGriffith from "../../../../src/docs/assets/profiles/harper-griffith.jpg";
import SloaneGriffith from "../../../../src/docs/assets/profiles/sloane-griffith.jpg";
import Guley from "../../../../src/docs/assets/profiles/guley.jpg";
import Hill from "../../../../src/docs/assets/profiles/hill.jpg";
import Howes from "../../../../src/docs/assets/profiles/howes.jpg";
import Isom from "../../../../src/docs/assets/profiles/isom.jpg";
import Johnson from "../../../../src/docs/assets/profiles/johnson.jpg";
import Koch from "../../../../src/docs/assets/profiles/koch.jpg";
import Kuhr from "../../../../src/docs/assets/profiles/kuhr.jpg";
import List from "../../../../src/docs/assets/profiles/list.jpg";
import Lockhart from "../../../../src/docs/assets/profiles/lockhart.jpg";
import Morrow from "../../../../src/docs/assets/profiles/Morrow.jpg";
import Reinbold from "../../../../src/docs/assets/profiles/reinbold.jpg";
import Romero from "../../../../src/docs/assets/profiles/romero.jpg";
import Snow from "../../../../src/docs/assets/profiles/snow.jpg";
import Stover from "../../../../src/docs/assets/profiles/charlie-stover.jpg";
import Mae from "../../../../src/docs/assets/profiles/stover.jpg";
import HudsonSullivan from "../../../../src/docs/assets/profiles/hudson-sullivan.jpg";
import JordanSullivan from "../../../../src/docs/assets/profiles/jordan-sullivan.jpg";
import Weir from "../../../../src/docs/assets/profiles/weir.jpg";
import * as React from 'react';
export default {
  Randomizer,
  ClientRandomizer,
  MillsCard,
  Break,
  Zoom,
  Agrusa,
  Baker,
  Caughell,
  Coates,
  Colburn,
  Failoni,
  Germain,
  Gibbons,
  HarperGriffith,
  SloaneGriffith,
  Guley,
  Hill,
  Howes,
  Isom,
  Johnson,
  Koch,
  Kuhr,
  List,
  Lockhart,
  Morrow,
  Reinbold,
  Romero,
  Snow,
  Stover,
  Mae,
  HudsonSullivan,
  JordanSullivan,
  Weir,
  React
};