import React from 'react';

function clientRandomizer(props) {
  const millsAds = [
    {
      image: '/sponsors/cafe-ad.jpg',
      link: 'https://tuacahn.org/',
      alt: 'Tuacahn Cafe',
      class: 'webFormat',
    },
    {
      image: '/sponsors/sat-market-gift.jpg',
      link: 'https://tuacahn.org/',
      alt: 'Tuacahn Gift gallery',
      class: 'webFormat',
    },
    {
      image: '/sponsors/senior-games.jpg',
      link: 'https://seniorgames.net/',
      alt: 'Huntsman World Senior Games',
      class: 'webFormat',
    },
    {
      image: '/sponsors/Tuacahn-2022-season.jpg',
      link: 'https://tuacahn.org/',
      alt: 'Tuacahn 2022 Season',
      class: 'webFormat',
    },
  ];

  const randomAd = millsAds[Math.floor(Math.random() * millsAds.length)];

  return (
    <>
      <hr />
      <br />
      <a href={randomAd.link} target="_blank" rel="noopener noreferrer">
        <img
          src={randomAd.image}
          alt={randomAd.alt}
          className={randomAd.class}
        />
      </a>
      <br />
      <br />
      <hr />
    </>
  );
}

export default clientRandomizer;
