import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

function Randomizer(props) {
  const millsAds = [
    {
      image: '/ads/kayenta.jpg',
      link: 'http://kayentahomes.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Tuacahn',
      alt: 'Kayenta Homes ad',
      class: 'webFormat',
    },
    {
      image: '/ads/shoppes-zion.jpg',
      link: 'http://kayentahomes.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Tuacahn',
      alt: 'Kayenta Homes ad',
      class: 'webFormat',
    },
    {
      image: '/ads/utah-arts-academy.jpg',
      link: 'https://utahartsacademy.org/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Tuacahn',
      alt: 'Utah Arts Academy ad',
      class: 'webFormat',
    },
    {
      image: '/ads/blvd.jpg',
      link: 'https://www.blvdhome.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Tuacahn',
      alt: 'BLVDHome ad',
      class: 'webFormat',
    },
    {
      image: '/ads/university_cu.jpg',
      link: 'https://www.ucreditu.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Tuacahn',
      alt: 'University Federal Credit Union',
      class: 'webFormat',
    },
    {
      image: '/ads/pony_georges.jpg',
      link: '#',
      alt: 'Painted Pony and Georges Corner ads',
      class: 'webFormat',
    },
    {
      image: '/ads/benja.jpg',
      link: 'https://www.benjathai.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Tuacahn',
      alt: 'Benjas Thai Garden ad',
      class: 'webFormat',
    },
    {
      image: '/ads/pbs.jpg',
      link: 'http://www.pbsutah.org/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Tuacahn',
      alt: 'PBS Utah ad',
      class: 'webFormat',
    },
  ];

  const [randomAd] = React.useState(
    millsAds[Math.floor(Math.random() * millsAds.length)]
  );

  return (
    <>
      <br />
      <OutboundLink
        href={randomAd.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={randomAd.image}
          alt={randomAd.alt}
          className={randomAd.class}
        />
      </OutboundLink>
      <br />
      <br />
      <hr />
      <br />
    </>
  );
}

export default Randomizer;
