export default {
  colors: {
    primary: '#41a5a4',
    background: '#023047',
    shape: `#F2F2FA`,
    title: `#41a5a4`,
    text: `#fff`,
    components: {
      blockquote: {
        background: `#feebc8`,
        text: `#2d3748`,
      },
    },
  },
};
