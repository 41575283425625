import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

function Advertisers() {
  const Users = [
    {
      id: '01',
      image: '/ads/kayenta.jpg',
      link: 'http://kayentahomes.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Tuacahn',
      alt: 'Kayenta Homes ad',
      class: 'webFormat',
    },
    {
      id: '02',
      image: '/ads/shoppes-zion.jpg',
      link: 'http://kayentahomes.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Tuacahn',
      alt: 'The Shoppes at Zion ad',
      class: 'webFormat',
    },
    {
      id: '03',
      image: '/ads/utah-arts-academy.jpg',
      link: 'https://utahartsacademy.org/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Tuacahn',
      alt: 'Utah Arts Academy ad',
      class: 'webFormat',
    },
    {
      id: '04',
      image: '/ads/blvd.jpg',
      link: 'https://www.blvdhome.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Tuacahn',
      alt: 'BLVDHome ad',
      class: 'webFormat',
    },
    {
      id: '05',
      image: '/ads/university_cu.jpg',
      link: 'https://www.ucreditu.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Tuacahn',
      alt: 'University Federal Credit Union',
      class: 'webFormat',
    },
    {
      id: '06',
      image: '/ads/pony_georges.jpg',
      link: '#',
      alt: 'Painted Pony and Georges Corner ads',
      class: 'webFormat',
    },
    {
      id: '07',
      image: '/ads/benja.jpg',
      link: 'https://www.benjathai.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Tuacahn',
      alt: 'Benjas Thai Garden ad',
      class: 'webFormat',
    },
    {
      id: '08',
      image: '/ads/pbs.jpg',
      link: 'http://www.pbsutah.org/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Tuacahn',
      alt: 'PBS Utah ad',
      class: 'webFormat',
    },
  ];

  return (
    <div>
      {Users.map((data) => (
        <details key={data.id}>
          <summary>{data.alt}</summary>
          <OutboundLink
            href={data.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={data.image} alt={data.alt} className={data.class} />
          </OutboundLink>
        </details>
      ))}
    </div>
  );
}

export default Advertisers;
