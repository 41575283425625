import Randomizer from "../../../../src/docs/components/randomizer";
import ClientRandomizer from "../../../../src/docs/components/clientrandomizer";
import Break from "../../../../src/docs/components/break";
import Board from "../../../../src/docs/assets/board-of-directors.jpg";
import * as React from 'react';
export default {
  Randomizer,
  ClientRandomizer,
  Break,
  Board,
  React
};