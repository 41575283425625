import React from 'react';

function Breawk(props) {
  return (
    <>
      <br />
      <br />
    </>
  );
}

export default Breawk;
