import Randomizer from "../../../../src/docs/components/randomizer";
import ClientRandomizer from "../../../../src/docs/components/clientrandomizer";
import MillsCard from "../../../../src/docs/components/millscard";
import Break from "../../../../src/docs/components/break";
import Zoom from 'react-reveal/Zoom'; // Importing Zoom effect

import { OutboundLink } from 'gatsby-plugin-google-analytics';
import TuacahnLogo from "../../../../src/docs/assets/tuacahnLogo.svg";
import * as React from 'react';
export default {
  Randomizer,
  ClientRandomizer,
  MillsCard,
  Break,
  Zoom,
  OutboundLink,
  TuacahnLogo,
  React
};