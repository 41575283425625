import Randomizer from "../../../../src/docs/components/randomizer";
import ClientRandomizer from "../../../../src/docs/components/clientrandomizer";
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Partners from "../../../../src/docs/assets/partners.jpg";
import * as React from 'react';
export default {
  Randomizer,
  ClientRandomizer,
  OutboundLink,
  Partners,
  React
};